import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../admin.css'
import { api } from '../../services/api.service'
import { setNotification, getPermissoes, GERENTE } from '../../services/dal.service'
import {
    Tag, Button, Card, Input, Space,
    Image, Badge, Alert, App, Switch
} from 'antd'
import mercadopagoImg from '../../img/mercadopago.png'
import { MP_ID, CLIENT_SECRET } from '../../services/api.service'
import './formas-de-pagamento.css'
import { CreditCardOutlined, CloseCircleFilled } from '@ant-design/icons';
import { isBrowser } from "react-device-detect";
import LogosCompaniesPayment from '../../img/logos-companies-payment.png'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'


export const FormasdePagamento = (props) => {

    const [salvando, setSalvando] = useState(false)
    const { token, tokenFull } = props
    const [bandeiras, setBandeiras] = useState([])
    const [bandeira, setBandeira] = useState({})
    const [loading, setLoading] = useState(true)
    const [pixEnableSalving, setPixEnableSalving] = useState(false)
    const [credentialMP, setCredentialMP] = useState({})
    //  const [codigoMp, setCodigoMp] = useState('')
    const [mercadoPagoStatus, setMercadoPagoStatus] = useState(0)  // 0 = carregando , 1 = vinculado  2 = desvinculado 3 = erro ao carregar
    const navigate = useNavigate()
    const permissions = getPermissoes(tokenFull)
    const { modal } = App.useApp()


    useEffect(() => {
        //console.log(tokenFull)
        load()
        loadMP()
    }, [])


    async function salvarPix(value) {
        try {
            setPixEnableSalving(true)
            await api.post('/api/v2.0/formas-de-pagamento/changepix',
                {
                    pixEnable: value === true ? 1 : 0
                },
                {
                    headers: {
                        'x-access-token': token,
                    },
                })

            setCredentialMP({ ...credentialMP, pixEnable: value === true ? 1 : 0 })
        } catch (error) {
            setNotification(error.message, 'error')
        }
        setPixEnableSalving(false)
    }


    async function load() {
        try {
            var response = await api.get('/api/v2.0/formas-de-pagamento', {
                headers: {
                    'x-access-token': token,
                },
            })

            var data = response.data
            data.map((item, index) => {
                item.index = index
                item.ordem = index
                return item
            })
            //  console.log('data', data)
            setBandeiras(data)
            setLoading(false)


        } catch (error) {
            setNotification(error.message, 'error')
            navigate('/login')
        }

    }



    async function loadMP() {
        try {

            var response = await api.get('/api/v2.0/formas-de-pagamento/credential-mp', {
                headers: {
                    'x-access-token': token,
                },
            })

            var data = response.data
            if (data.data !== undefined) {
                // setCurrentStep(4)
                setMercadoPagoStatus(1)
                setCredentialMP(data.data)
            } else {
                //   setCurrentStep(1)
                setMercadoPagoStatus(2)
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
            setMercadoPagoStatus(3)
        }

    }


    async function save() {
        try {
            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }
            setSalvando(true)
            var response = await api.post('/api/v2.0/formas-de-pagamento', bandeira, {
                headers: {
                    'x-access-token': token,
                },
            })
            if (response.status === 200) {
                setNotification('Suas formas de pagamento foram atualizadas!', 'success')
                setBandeira({
                    Situacao: 1,
                    bandeira: "",
                    Url: ''
                })
                load()

            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
        setSalvando(false)
    }



    async function deleteFp(fpid) {
        try {

            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            var response = await api.delete('/api/v2.0/formas-de-pagamento?fpid='.concat(fpid), {
                headers: {
                    'x-access-token': token,
                }
            })
            if (response.status === 200) {
                setNotification('Forma de pagamento excluida com sucesso!', 'success')
                load()
            } else {
                setNotification('O servidor não está respondendo com dados válidos!', 'error')
            }

        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
    }

    async function deleteMP() {
        try {

            if (!permissions?.includes(GERENTE)) {
                modal.warning({
                    title: "Acesso negado!",
                    content: "Você não possui permissão para executar essa operação! "
                })
                return
            }

            await api.delete('/api/v2.0/deleteMPCredencial', {
                headers: {
                    'x-access-token': token,
                }
            })
            setNotification('Sua conta Mercado pago foi desvinculada com sucesso!', 'success')
            loadMP()
        } catch (error) {
            console.log('error', error.message)
            setNotification(error.message, 'error')
        }
    }


    return isBrowser ? renderDesktop() : renderMobile()


    function renderDesktop() {
        return <div className="app-window">
            {
                renderContextArea2()
            }
        </div>
    }

    function renderMobile() {
        return <div style={{ backgroundColor: 'white' }}>
            {
                renderContextArea2()
            }
        </div>
    }


    function renderContextArea2() {
        return <Card style={{ margin: isMobile ? 0 : 20, width: '100%' }} title={null}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={'subtitle'}>
                    Habilite <span style={{ color: 'royalblue' }}> um ou mais </span> métodos de pagamento
                </div>

                <Card title={''}
                    style={{
                        backgroundColor: 'whitesmoke',
                        marginTop: 10
                    }}
                    className={'subtitle'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10
                    }}>
                        <div style={{ color: 'royalblue' }}>Ativar pagamentos manuais (pagamento na entrega)</div>
                    </div>

                    <div>
                        <Space direction={isMobile ? 'vertical' : 'horizontal'} style={{ color: 'darkgray' }}>
                            Adicionar novo meio de pagamento:
                            <Input placeholder='Exemplo: mastercard'
                                value={bandeira?.bandeira}
                                onChange={(e) => {
                                    setBandeira({ ...bandeira, bandeira: e.target.value })
                                }} />
                            <Button type='default'
                                icon={<CreditCardOutlined />}
                                disabled={!bandeira?.bandeira}
                                loading={salvando}
                                onClick={() => {
                                    save()
                                }}>+ Adicionar</Button>
                        </Space>
                        <div>
                            <Space size={'small'} direction='horizontal' style={{ marginTop: 10 }} wrap>
                                {
                                    bandeiras?.map((item, index) => {
                                        return <Tag
                                            closable
                                            closeIcon={<CloseCircleFilled style={{ fontSize: '1.5em', marginLeft: 4 }}
                                                onClick={() => {
                                                    deleteFp(item.fpid)
                                                }}

                                            />}
                                            style={{
                                                backgroundColor: 'white',
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 7,
                                                paddingRight: 7
                                            }}
                                            key={"keyban-".concat(index, '-', item.bandeira)}
                                            onClose={(e) => {
                                                e.preventDefault();
                                                // handleClose(tag);
                                            }}>
                                            {item.bandeira}
                                        </Tag>
                                    })
                                }
                            </Space>
                        </div>
                    </div>


                </Card>



                <Card title={null} style={{ backgroundColor: 'whitesmoke', marginTop: 10 }} className={'subtitle'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10
                    }}>
                        <div style={{ color: 'royalblue' }}>Ativar pagamentos online 🚀</div>

                    </div>

                    <div className='subtitle'>
                        Crie ou conecte-se a uma conta no Mercado pago
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        marginTop: 10
                    }}>
                        <Image height={150} width={150}
                            preview={false}
                            alt="mercadopago" src={mercadopagoImg} />
                        <div style={{ marginLeft: 25 }}>
                            <div>
                                {
                                    mercadoPagoStatus === 2 ? <div>Conecte a sua conta ao Mercado Pago. </div>
                                        : <Space direction='vertical'>
                                            <Space direction={isMobile ? 'vertical' : 'horizontal'}>
                                                <Badge color='green' text={<span style={{ color: 'royalblue' }}>A sua conta no Mercado pago está conectada! </span>} />
                                                <a href='#'
                                                    style={{
                                                        fontSize: '0.9em', color: '#696969', textDecoration: 'underline',
                                                        fontFamily: 'Roboto, sans-serif', letterSpacing: '.0333333333em'
                                                    }}
                                                    onClick={() => deleteMP()}> Desconectar a minha conta
                                                </a>
                                            </Space>
                                            <Space direction='horizontal' size={'small'}>
                                                <Switch checked={credentialMP?.pixEnable === 1}
                                                    disabled={pixEnableSalving}
                                                    onChange={(e) => {
                                                        salvarPix(e)
                                                    }} />
                                                <div style={{ color: '#4F4F4F' }}>
                                                    <b>Ativar PIX </b>- Para receber pagamento por PIX você deve ter configurado/criado sua chave PIX em sua conta MERCADOPAGO
                                                </div>
                                            </Space>
                                        </Space>
                                }
                                <div style={{ marginTop: 15 }}>Métodos de pagamento disponíveis:</div>
                                <div>
                                    <Image
                                        height={20}
                                        preview={false}
                                        alt="mercadopago" src={LogosCompaniesPayment} />
                                </div>
                                {
                                    mercadoPagoStatus === 2 && <div style={{ marginTop: 15 }}>Ainda não tem uma conta?
                                        <a href='https://www.mercadopago.com.br/' target='_blank' style={{ marginLeft: 5 }}>
                                            Clique aqui para criar a sua conta!</a>
                                    </div>
                                }


                                {
                                    mercadoPagoStatus === 2 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 7 }}>
                                        <Button size='large' type='primary'
                                            style={{
                                                textAlign: 'center',
                                                height: 50,
                                                lineHeight: 1.07,
                                                paddingTop: 2,
                                                paddingBottom: 2,
                                                fontWeight: 500
                                            }}
                                            onClick={() => {

                                                window.location.replace('https://auth.mercadopago.com.br/authorization?client_id='
                                                    .concat(MP_ID, '&response_type=code&platform_id=mp&state=',
                                                        tokenFull.path,
                                                        '&redirect_uri=https://portal.menuengfood.com.br/authorization-code'))

                                            }}>
                                            <div>Conectar a minha conta no</div>
                                            <div>Mercado pago</div>
                                        </Button>
                                    </div>
                                }


                                <div style={{ marginTop: 15 }}>
                                    <Alert
                                        description={<div>
                                            <div>
                                                Para pagamento Online será cobrada as taxas do Mercado Pago acrescidas 1% sobre o valor do pedido.
                                                Para mais detalhes sobre as taxas do Mercado pago acesse sua conta Mercado pago.
                                            </div>
                                            <div> 
                                                Em sua conta Mercadopago na opção <b>Seu Negócio/Custos</b> você configura quando deseja receber o 
                                                valor das suas vendas.(Na hora, em 14 ou 30 dias).
                                                </div>
                                        </div>}
                                        showIcon
                                        type='info' />
                                </div>

                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Card>
    }

}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
    tokenFull: state.tokenReducer.tokenFull
})

const mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(FormasdePagamento)
