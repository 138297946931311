import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BASEURL, MP_ID, ACCESS_TOKEN_MP, CLIENT_SECRET } from '../../services/api.service'
import { api } from '../../services/api.service'
import { setNotification } from '../../services/dal.service'
import mercadopagoImg from '../../img/mercadopago.png'
import { Image } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export const AuthorizationCode = (props) => {

    const { token } = props
    const navigate = useNavigate()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const path = urlParams.get('path');
        if (code) {
            gerarCredencial(code, path)
        } else {
            props.history.push('/formas-de-pagamento')
            setNotification('Eu ao gerar credenciais!', 'error')
        }
        //  console.log(myParam)
    }, [])


    async function gerarCredencial(code, path) {
        try {

            var url = "https://api.mercadopago.com/oauth/token?client_secret="
            .concat(ACCESS_TOKEN_MP,"&grant_type=authorization_code&code=", code,
            "&redirect_uri=https://portal.menuengfood.com.br/authorization-code")
            
            const response = await axios.post(url)

            const response2 = await api.post("/api/v2.0/formas-de-pagamento/credential-mp",
                response.data, {
                headers: {
                    'x-access-token': token,
                }
            })
           
            setNotification('As credenciais foram salva com sucesso', 'success')
            navigate('/formas-de-pagamento')

        } catch (error) {
            console.log('error: ', error.message)
            setNotification(error.message, 'error')
        }
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, textAlign: 'center' }}>
                <Image height={150} width={150}
                    preview={false}
                    alt="mercadopago" src={mercadopagoImg} />
                <i><LoadingOutlined /> Processando...</i>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationCode)



